.premium {
  margin: 30px 0;

  .cards {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    .card {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;

      box-shadow:
              0 1px 2px -2px rgba(0, 0, 0, 0.16),
              0 3px 6px 0 rgba(0, 0, 0, 0.12),
              0 5px 12px 4px rgba(0, 0, 0, 0.09);

      .list {
        list-style-type: "✅ ";
        .list__item {

        }


      }

      &__info {
        min-height: 40px;
        font-size: 12px;
        margin: 5px 0;
        color: dimgrey;
      }

      .price {
        font-size: 24px;

        .amount {
          font-weight: 600;

          &__discount {
            text-decoration: line-through;
            font-weight: normal;
          }
        }
      }
    }
  }
}