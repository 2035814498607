.lessons {
  display: grid;
  grid-gap: 20px;

  .allCourses {
    margin: -10px 5px;

    &:hover {
      cursor: pointer;
      text-decoration-color: black;

    }
  }

  .item {
    &_nextCompleted > div:first-child {
      background-color: lightgreen;
    }

    .card {
      position: relative;

      &__tasks {
        position: absolute;
        bottom: 4px;
        right: -4px;
      }
      // margin-left: 20px;
      // margin-right: 40px;
    }
  }

  .course {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 40px;
    min-height: 90px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 15px;
    background: rgba(102, 204, 255, 0.55);
    font-family: "Montserrat", Arial, Helvetica, sans-serif;

    .course__right {
      position: relative;
      height: 100%;

      .course__title {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .picture {
      margin: -10px;
      margin-bottom: -15px;
      margin-left: -15px;
      border-radius: 10px;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;

      background-size: cover;
      background-position: center;
    }

    .courseProgress {
      position: absolute;
      bottom: -10px;
      width: 100%;
    }
  }

  &_mobile {
    .course {
      margin: 10px 0;
      display: block;

      &__description {
        font-size: 14px;
      }

      &__html {
        font-size: 14px;

      }

      .picture {
        display: none;
      }
    }
  }
}
