.grammarPanel {
  font-size: 1.2em;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  //img {
  //  vertical-align: middle;
  //  width: 24px;
  //  margin-right: 10px;
  //
  //  &:hover {
  //    cursor: pointer;
  //  }
  //}

  img {
    width: 100%;
  }

  blockquote {
    background: #ddfaff;
    border-radius: 5px;
    color: #1d2a57;
    padding: 10px 10px 10px 20px;
    margin: 0;

    border-left: 4px solid #00337c;

    p {
      margin: 5px;
    }
  }

  &__mobile {
    font-size: unset;

    blockquote {
      display: grid;
      grid-gap: 10px;
      align-items: center;
      margin: 0;
      margin-right: -10px;
    }
  }
}