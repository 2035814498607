.grammarPage {
  font-size: 1.2em;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  .title {
    font-size: 1.4em;
    font-weight: 500;
  }

  blockquote {
    background: #ddfaff;
    border-radius: 5px;
    color: #1d2a57;
    padding: 10px 10px 10px 20px;
    margin: 0;

    border-left: 4px solid #00337c;

    p {
      margin: 5px;
    }
  }

  &__mobile {
    font-size: unset;

    .title {
      font-size: 1.1em;
    }

    img {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    video {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
  }
}
